import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import Container from 'components/ui/Container';
import * as Styled from './styles';
import { changeLoginPassword, selectUser$ } from '../../state/user/user.service';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { isRunTime, setLocalStorageItem } from '../../state/utils';
import { useObservable } from '@libreact/use-observable';
import Danger from '../ui/Alert/Danger';
import Info from '../ui/Alert/Info';

const ChangePassword = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiSetPasswordPage_en: strapiSetPasswordPage(locale: { eq: "en" }) {
        create_your_password
        confirm_password
        submit
        current_password
        new_password
      }
      strapiSetPasswordPage_zh: strapiSetPasswordPage(locale: { eq: "zh" }) {
        create_your_password
        confirm_password
        submit
        current_password
        new_password
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: { eq: "en" }) {
        invalid_password
        password_required
        weak_password
        confirm_password_required
        confirm_password_not_matching
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        invalid_login_credentials
        incorrect_current_password_entered
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: { eq: "zh" }) {
        invalid_password
        password_required
        weak_password
        confirm_password_required
        confirm_password_not_matching
        unknown_error
        server_error_500
        server_error_502
        request_error_422
        invalid_login_credentials
        incorrect_current_password_entered
      }
      strapiSuccessMessages_en: strapiSuccessMessages(locale: { eq: "en" }) {
        password_change_successful
      }
      strapiSuccessMessages_zh: strapiSuccessMessages(locale: { eq: "zh" }) {
        password_change_successful
      }
    }
  `);
  const translations = gql[`strapiSetPasswordPage_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const successTranslations = gql[`strapiSuccessMessages_${locale}`];
  const { navigate } = useI18next();
  const [user] = useObservable(selectUser$);
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([]);

  if (isRunTime() && !user.id) {
    setLocalStorageItem('uStoreAccessRedirectBackTo', '/shop/');
    navigate('/login/');
  }

  return (
    <Container section>
      <Styled.Wrapper>
        <Styled.Mob>
          <Styled.MobileTtitle>{translations.create_your_password}</Styled.MobileTtitle>
        </Styled.Mob>
        <br />
        <Formik
          initialValues={{ currentPassword: '', newPassword: '', confirmedPassword: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.currentPassword) {
              errors.currentPassword = errTranslations.password_required
            }

            if (!values.newPassword) {
              errors.newPassword = errTranslations.password_required
            } else if (
              !values.newPassword.match(
                /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/g
              )
            ) {
              errors.newPassword = errTranslations.weak_password
            }

            if (!values.confirmedPassword) {
              errors.confirmedPassword = errTranslations.confirm_password_required;
            }

            if (values.confirmedPassword && values.newPassword && (values.confirmedPassword !== values.newPassword)) {
              errors.confirmedPassword = errTranslations.confirm_password_not_matching;
            }

            return errors;
          }}
          onSubmit={async (values) => {
            setErrors([]);
            setMessages([]);
            const setPasswordResponse = await changeLoginPassword(
              values.currentPassword,
              values.newPassword,
              values.confirmedPassword
            )
              .toPromise()
              .catch((ajax) => {
                switch (ajax.response.message) {
                  case 'password_confirm_not_matching':
                    setErrors([errTranslations.confirm_password_not_matching]);
                    break;
                  case 'old_password_incorrect':
                    setErrors([errTranslations.incorrect_current_password_entered]);
                    break;
                  default:
                    switch (ajax.status) {
                      case 500:
                        setErrors([errTranslations.server_error_500]);
                        break;
                      case 502:
                        setErrors([errTranslations.server_error_502]);
                        break;
                      case 400:
                        setErrors([errTranslations.request_error_422]);
                        break;
                      case 401:
                        setErrors([errTranslations.incorrect_current_password_entered]);
                        break;
                      case 422:
                        setErrors([errTranslations.request_error_422]);
                        break;
                      default:
                        setErrors([errTranslations.unknown_error]);
                        break;
                    }
                    break;
                }
              });

            if (!!setPasswordResponse) {
              setMessages([successTranslations.password_change_successful]);
              setTimeout(async () => {
                await navigate(`/shop/`);
              }, 3000);
            }
          }}
        >
          {(formikProps) => (
            <Form>
              <Styled.Text small>{translations.current_password}</Styled.Text>
              <Field type="password" name="currentPassword">
                {({ field, meta: { touched, error } }) => (
                  <input
                    className={touched && error ? 'bg-red-100 border border-red-100' : ''}
                    type="password"
                    {...field}
                  />
                )}
              </Field>
              <ErrorMessage
                name="currentPassword"
                component="div"
                className="mt-1 text-xs text-red-600"
              />
              <Styled.Text small>{translations.new_password}</Styled.Text>
              <Field type="password" name="newPassword">
                {({ field, meta: { touched, error } }) => (
                  <input
                    className={touched && error ? 'bg-red-100 border border-red-100' : ''}
                    type="password"
                    {...field}
                  />
                )}
              </Field>
              <ErrorMessage
                name="newPassword"
                component="div"
                className="mt-1 text-xs text-red-600"
              />
              <Styled.Text small>{translations.confirm_password}</Styled.Text>
              <Field type="password" name="confirmedPassword">
                {({ field, meta: { touched, error } }) => (
                  <input
                    className={touched && error ? 'bg-red-100 border border-red-100' : ''}
                    type="password"
                    {...field}
                  />
                )}
              </Field>
              <ErrorMessage
                name="confirmedPassword"
                component="div"
                className="mt-1 text-xs text-red-600"
              />
              <Styled.Button type="submit" disabled={formikProps.isSubmitting} className={formikProps.isValid && formikProps.dirty ? "enabledBtn text-white" : "bg-gray-200 w-full text-gray-500"}>
                {translations.submit}
              </Styled.Button>
            </Form>
          )}
        </Formik>
        {errors.map((err) => (
          <Danger message={err} />
        ))}
        {messages.map((msg) => (
          <Info message={msg} />
        ))}
      </Styled.Wrapper>
    </Container>
  );
};

ChangePassword.propTypes = {
  locale: PropTypes.string,
};

ChangePassword.defaultProps = {
  locale: 'en',
};

export default ChangePassword;
