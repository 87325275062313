import React from 'react';
import PropTypes from 'prop-types';

const Info = ({ message }) => (
  <div
    className="bg-green-50 border border-green-400 text-gray-600 px-4 py-2 rounded relative mt-2 text-sm"
    role="alert"
  >
    <span className="block sm:inline">{message}</span>
  </div>
);

Info.propTypes = {
  message: PropTypes.string,
};

export default Info;
