import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ChangePassword from '../components/ChangePassword';
import { getQueryVariable } from "../state/utils";
import { I18nextContext } from "gatsby-plugin-react-i18next";

const Password: React.FC = (props: any) => {
  const { language } = React.useContext(I18nextContext);
  const token = getQueryVariable('token', props.location)
  return (
    <Layout page='change-password' locale={language}>
        <SEO title="Change Password" />
        <ChangePassword token={token} locale={language}/>
    </Layout>
  );
};

export default Password;
